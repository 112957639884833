//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  auth: false,
  layout: 'centered',
  data: () => ({
    formName: 'login',
    formData: {},
    loggingIn: false,
  }),
  mounted() {
    this.$store.commit('SET_PAGE_TITLE', 'Inloggen');
  },
  methods: {
    async submit() {
      this.loggingIn = true;
      try {
        // Pass form data to `loginWith` function
        await this.$auth.loginWith('local', {data: this.formData});

        // Redirect user after login
        await this.$router.push(this.localeRoute({name: 'index'}));
      } catch (err) {
        if (err.response) {
          if (err.response.status === 422) {
            await this.$toast.error('Het ingevoerde e-mailadres of wachtwoord is onjuist.');
          } else {
            await this.$toast.error('Er is iets mis gegaan tijdens het inloggen. Probeer het later nog eens.');
          }
        }
      } finally {
        this.loggingIn = false;
      }
    },
  },
};
